import { ReceiptLong } from "@mui/icons-material";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import {
  Alert,
  AlertTitle,
  Card,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { formatCurrency } from "../../common/helpers";
import BankPaymentProcessingDialog from "../../components/Payment/BankPaymentProcessingDialog";
import LatestPayment from "../../components/Payment/LatestPayment";
import PaymentDetailsRow from "../../components/Payment/PaymentDetailsRow";
import { RootState } from "../../redux/store";
import { ACHTransaction, PaymentType } from "../../types/paymentTypes";

const CardTransactionPage: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const transactions = useSelector<RootState, ACHTransaction[]>(
    (state) => state.card.transactions
  );

  const transaction = transactions.find(
    (payment) => payment.transaction_id === id
  );

  const transactionAmount = formatCurrency(transaction?.amount ?? 0, true);
  const actionColor = useTheme().palette.action.active;

  const failedPayment = transaction?.status === "failed";

  // Modal state and handlers
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid container direction="column">
      <Card variant="outlined">
        <List sx={{ paddingBottom: "0px" }}>
          <ListItem sx={{ padding: "0px" }}>
            <PaymentDetailsRow
              title={t("payment_amount")}
              description={transactionAmount}
              icon={<MonetizationOnIcon color="action" />}
              descriptionColor={
                (transaction?.amount ?? 0) >= 0 ? "green" : "red"
              }
              boldDescription={true}
            />
          </ListItem>
          <Divider component="li" />

          {transaction?.payment_account && (
            <>
              <ListItem sx={{ padding: "0px" }}>
                <LatestPayment
                  paymentAccount={transaction.payment_account}
                  failedPayment={failedPayment}
                  showChangeButton={false}
                  useTableStyle={true}
                />
              </ListItem>
              <Divider component="li" />
            </>
          )}

          <ListItem sx={{ padding: "0px" }}>
            <PaymentDetailsRow
              title={t("payment_transactionId")}
              description={transaction?.transaction_id ?? "-"}
              icon={<ReceiptLong color="action" />}
            />
          </ListItem>

          {transaction?.status && (
            <ListItem sx={{ padding: "0px" }}>
              <Alert
                sx={{
                  width: "100%",
                  ".MuiAlert-icon": {
                    color: actionColor,
                  },
                }}
                severity={"info"} // statusSeverity(transaction.status)
              >
                <AlertTitle>
                  <Typography
                    data-test-id="status"
                    variant="subtitle2"
                    color="text.secondary"
                  >
                    {t("payment_status")}
                  </Typography>
                </AlertTitle>
                <Typography
                  data-test-id="status-value"
                  variant="body2"
                  color="text.primary"
                >
                  {transaction.status}
                </Typography>
                {transaction.payment_account?.target_account_type ===
                  PaymentType.BankAccount && (
                  <Link
                    textAlign={"left"}
                    component="button"
                    variant="body2"
                    onClick={handleClickOpen}
                  >
                    {t("bank_processing_title")}
                  </Link>
                )}
              </Alert>
            </ListItem>
          )}
        </List>
      </Card>
      <BankPaymentProcessingDialog open={open} onClose={handleClose} />
    </Grid>
  );
};

export default CardTransactionPage;
