import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Paper,
  Skeleton,
} from "@mui/material";
import PaymentIcon from "@mui/icons-material/Payment";
import { useAPI } from "../../api/api";
import { PaymentType, VirtualCardResponse } from "../../types/paymentTypes";
import { getVirtualCard } from "../../api/paymentMethodsService";
import { CardData } from "../../types/common";
import { useDispatch, useSelector } from "react-redux";
import { setCardData, setVccData } from "../../redux/slices/vccSlice";
import { useErrorBoundary } from "react-error-boundary";
import { formatCurrency } from "../../common/helpers";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface ManageCardProps {
  buttonLabel?: string;
  buttonHandler?: (balance: number) => void;
}

const ManageCard: React.FC<ManageCardProps> = ({
  buttonHandler,
  buttonLabel,
}) => {
  const dispatch = useDispatch();
  const { showBoundary } = useErrorBoundary();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data: vccData,
    loading: isVCCLoading,
    error: vccError,
  } = useAPI<VirtualCardResponse[]>(getVirtualCard());

  useEffect(() => {
    if (vccData && vccData[0]) {
      const transformed: CardData = {
        target_account_id: vccData[0].id,
        card_number: vccData[0].card_number,
        balance: vccData[0].balance,
        type: PaymentType.VirtualCardV2,
      };
      dispatch(setCardData(transformed));
    }
  }, [vccData, dispatch]);

  useEffect(() => {
    if (!vccError) {
      return;
    }

    // if 404, the user doesn't have a vcc
    if (vccError.response?.status == 404) {
      // navigate("/card-onboarding", { replace: true });
      return;
    }

    showBoundary(vccError);
  }, [vccError]);

  // if (isVCCLoading)
  //   return (
  //     <Paper data-test-id="payments-skeleton" elevation={0}>
  //       <Skeleton variant="rounded" height={144.5} />
  //     </Paper>
  //   );

  if (!isVCCLoading && !vccData) return null;

  return (
    <>
      {vccData && vccData.length > 0 && (
        <Card
          variant="outlined"
          sx={{ border: "none", backgroundColor: "#123B42" }}
        >
          <CardContent
            sx={{
              position: "relative",
              backgroundImage: "url(/card/frog.svg)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right top",
              backgroundSize: "auto 80%",
              color: "white",
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h5">
                {formatCurrency(vccData[0].balance, false)}
              </Typography>
            </Box>

            <Typography variant="body2">{t("vcc_availableBalance")}</Typography>

            <Button
              startIcon={<PaymentIcon />}
              variant="outlined"
              sx={{
                marginTop: 2,
                color: "white",
                borderColor: "white",
                ":hover": { borderColor: "white" },
              }}
              onClick={() => {
                if (buttonHandler) buttonHandler(vccData[0].balance ?? 0);
                else navigate("/card/details/2");
              }}
            >
              {buttonLabel ?? t("card_manage")}
            </Button>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default ManageCard;
