import {
  Button,
  FilledInput,
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatCurrency } from "../../common/helpers";
import { selectCurrencySymbol } from "../../redux/slices/paymentMethodsSlice";
import { RootState } from "../../redux/store";
import ManageCard from "./ManageCard";
import { Box } from "@mui/system";
import { setTransferAmount } from "../../redux/slices/cardSlice";
import { preventNegativeValues } from "../../common/constants";

const TransferPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currencySymbol = useSelector((state: RootState) =>
    selectCurrencySymbol(state)
  );
  const cardBallance = useSelector(
    (state: RootState) => state.vcc.vcc2Data!.balance ?? 0
  );
  const savedTransferAmount = useSelector(
    (state: RootState) => state.card.transferAmount
  );

  const [amount, setAmount] = useState<number | undefined>(undefined);
  const [errors, setErrors] = useState("");

  useEffect(() => {
    if (savedTransferAmount) setAmount(savedTransferAmount);
  }, []);

  const onAmountInput = (event: any) => {
    const inputValue = event.target.value;
    const newValue = parseFloat(inputValue);

    if (inputValue && isNaN(newValue)) {
      setErrors(t("invalid_value").toString());
      setAmount(undefined);
      return;
    }

    setErrors("");
    setAmount(newValue);

    if (newValue > parseFloat(cardBallance.toString())) {
      setErrors(t("insufficient_balance").toString());
    }
  };

  function handleButton() {
    if (!amount) return;
    dispatch(setTransferAmount(amount));
    navigate("/transfer/review");
  }

  return (
    <Paper
      elevation={0}
      sx={{
        minHeight: "calc(100vh - 56px - 32px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        pb: 1,
      }}
    >
      <Box>
        <ManageCard
          buttonLabel="Move the full amount"
          buttonHandler={(balance) => setAmount(balance)}
        />

        <Box sx={{ px: 2, mt: 2 }}>
          <FormControl fullWidth variant="filled">
            <InputLabel htmlFor="amount">{t("payment_amount")}</InputLabel>
            <FilledInput
              id="amount"
              value={amount}
              startAdornment={
                <InputAdornment position="start">
                  {currencySymbol}
                </InputAdornment>
              }
              onKeyDown={preventNegativeValues}
              onChange={onAmountInput}
              type="number"
              data-test-id="transfer-amount"
              error={!!errors}
            />
            <FormHelperText error={!!errors}>{errors}</FormHelperText>
          </FormControl>
        </Box>
      </Box>
      <Box sx={{ px: 2 }}>
        <Button
          size="large"
          style={{ marginBottom: 10 }}
          variant="contained"
          onClick={handleButton}
          fullWidth
          disabled={!amount || amount <= 0 || !!errors}
        >
          {t("next")}
        </Button>
      </Box>
    </Paper>
  );
};

export default TransferPage;
